<template>
  <div>
    <v-card class="custom-table" :class="{'hide-shadow': disableShadow}">
      <v-toolbar flat height="auto" class="custom-table-toolbar" v-if="!disableToolbar">
        <v-toolbar-items class="toolbar-left-items">
          <!-- Search Bar -->
          <searchbar  v-if="enableSearch"
            :searchText.sync="searchText"
            :searchPlaceholder="searchPlaceholder"
            :searchType="searchType"
            :searchTypeOptions="searchTypeOptions"
            @search-clicked="$emit('search-clicked', $event)"
            @search-input="$emit('search-input', $event)"
            @search-type-changed="$emit('update:searchType', $event)"
            @search-clear="$emit('search-cleared')"
            :enableMultiTypeSearch="enableMultiTypeSearch"
            enabledClear
          ></searchbar>

          <slot name="custom-toolbar-slot"></slot>
        </v-toolbar-items>

        <v-spacer></v-spacer>

        <v-toolbar-items class="toolbar-right-items">
          <v-btn v-if="enableClearAll"
            depressed text small
            color="error"
            @click="clickClearAll()"
          >
            <v-icon class="mr-1" color="error">{{ icon.mdiClose }}</v-icon>
            清除搜尋
          </v-btn>
          <v-btn v-if="enableRefresh"
            depressed text small
            color="success"
            @click="clickRefresh()"
          >
            <v-icon class="mr-1" color="success">{{ icon.mdiRefresh }}</v-icon>
            重新載入
          </v-btn>
          <v-btn v-if="enableExport"
            depressed text small
            color="primary"
            @click="clickExport()"
          >
            <v-icon class="mr-1" color="primary">{{ icon.mdiTableArrowRight }}</v-icon>
            匯出資料
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-data-table
        v-model="selectedRow"
        class="custom-table"
        :class="{
          'input-datatable': isInputTable,
          'select-datatable': enableSelectToggle
        }"
        locale="zh-cn"
        :loading="processing"
        :headers="tableHeaders"
        :items="tableData"
        :items-per-page="disablePagination ? -1 : pageLimit"
        :page="page"
        :server-items-length="disablePagination ? tableData.length : itemTotal"
        :options.sync="options"
        :height="isFixedHeader ? 'calc(3.125rem * ' + (pageLimit + 1.1) + ')' : ''"
        :fixed-header="isFixedHeader"
        :show-select="enableSelectToggle"
        :show-expand="enableExpand"
        :show-group-by="enableGroupBy"
        :single-select="isSingleSelect"
        :single-expand="isSingleExpand"
        :hide-default-header="disableHeader || hideDefaultHeader"
        :hide-default-footer="disableFooter"
        :disable-filtering="!enableFiltering"
        :disable-pagination="disablePagination"
        :disable-sort="!enableSort"
        :multi-sort="isMultiSort"
        :must-sort="isMustSort"
        :sort-by="defaultSortKey"
        :sort-desc="defaultSortDesc"
        :header-props="{
          sortByText: '資料排序',
        }"
        :footer-props="{
          showCurrentPage: true,
          showFirstLastPage: !disableFirstLastPage,
          disablePagination: disablePagination,
          itemsPerPageText: '每頁顯示項目:',
          itemsPerPageOptions: pageLimitOptions,
          itemsPerPageAllText: '全部',
          pageText: `顯示第${tableData.length > 1 ? `${(page - 1) * pageLimit + 1}-${Math.min(page * pageLimit, itemTotal === -1 ? tableData.length : itemTotal)}` : (page - 1) * pageLimit + 1}個項目`
        }"
        loading-text="資料處理中"
        :no-data-text="tableEmptyText"
        :no-results-text="tableEmptyText"
        :dense="isDense"
        :mobile-breakpoint="breakpoint"
      >
        <!-- Top Pagination -->
        <template v-slot:top="{ pagination, options, updateOptions }" v-if="showTopSlot && !disablePagination">
          <v-data-footer
            class="hide-page-select"
            :show-current-page="true"
            :disable-items-per-page="true"
            :pagination="pagination"
            :options="options"
            :show-first-last-page="!disableFirstLastPage"
            :items-per-page-text="''"
            :page-text="`共${(itemTotal % pageLimit == 0 ? itemTotal / pageLimit : parseInt(itemTotal / pageLimit) + 1)}頁 / ${itemTotal}個項目`"
            @update:options="updateOptions"
          ></v-data-footer>
        </template>

        <!-- pass through scoped slots -->
        <template v-for="(_, scopedSlotName) in $scopedSlots" v-slot:[scopedSlotName]="slotData">
          <slot :name="scopedSlotName" v-bind="slotData" />
        </template>

        <!-- pass through normal slots -->
        <template v-for="(_, slotName) in $slots" v-slot:[slotName]>
          <slot :name="slotName" />
        </template>

        <!-- Body item -->
        <template v-for="head in tableHeaders" v-slot:[`item.${head.value}`]="{ item, header }">
          <form-input v-if="header.input === true"
            :fieldValue.sync="item[header.value]"
            hideDetails
            dense
            dispatchUpdateOnChange
            v-bind="header.itemInput"
            :disabled="disabledInput(item, header)"
            @input-value="inputUpdate(item.id, header.value, $event)"
          ></form-input>
          <form-select v-else-if="header.isSelect === true"
            :fieldValue.sync="item[header.value]"
            hideDetails
            dense
            dispatchUpdateOnChange
            hideArrowIon
            v-bind="header.itemInput"
            :disabled="disabledInput(item, header)"
            @changed="inputUpdate(item.id, header.value, $event)"
          ></form-select>
          <slot v-else 
            :name="`item.${header.value}`" 
            :item="item" 
            :header="header"
          >{{$validate.DataValid(item[header.value]) ? (header.prefix || '') : '' }}{{ item[header.value] }}</slot>
        </template>


        <!----------------------- Common ----------------------->
        <template v-slot:[`item.create_date`]="{ item }">
          <span v-if="$validate.DataValid(item.create_date)">{{ $Formatter.displayCreateDate(item.create_date) }}</span>
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <span v-if="item.status === 'active'">啟用中</span>
          <span v-else class="error--text">停用</span>
        </template>

        <!----------------------- Action ----------------------->
        <template v-slot:[`item.actionViewDelete`]="{ item, header }">
          <v-btn depressed text color="secondary" small @click="clickActionView(item.id)">
            <v-icon class="mr-1" small>{{ icon.mdiEyeOutline }}</v-icon>
            {{ header.viewTitle || '查看' }}
          </v-btn>
          <v-btn class="ml-4" depressed text color="error" small @click="clickActionDelete(item.id)">
            <v-icon class="mr-1" small>{{ icon.mdiTrashCanOutline }}</v-icon>
            {{ header.deleteTitle || '刪除'}}
          </v-btn>
        </template>

        <template v-slot:[`item.actionView`]="{ item, header }">
          <v-btn depressed text color="secondary" small @click="clickActionView(item.id)">
            <v-icon class="mr-1" small>{{ icon.mdiEyeOutline }}</v-icon>
            {{ header.viewTitle || '查看' }}
          </v-btn>
        </template>

        <template v-slot:[`item.actionDelete`]="{ item, header }">
          <v-btn class="ml-4" depressed text color="error" small @click="clickActionDelete(item.id)">
            <v-icon class="mr-1" small>{{ icon.mdiTrashCanOutline }}</v-icon>
            {{ header.deleteTitle || '刪除'}}
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>

import {
  mdiEyeOutline,
  mdiCheck,
  mdiClose,
  mdiRefresh,
  mdiMagnify,
  mdiCalendarSearch,
  mdiTableArrowRight,
  mdiTrashCanOutline,
} from '@mdi/js'
import Searchbar from '@/components/Searchbar.vue';
import FormInput from '@/components/form/FormInput.vue';
import FormSelect from '@/components/form/FormSelect.vue';

export default {
  // ------ page properties ------
  name: 'Datatable',
  components: {
    Searchbar,
    FormInput,
    FormSelect,
},
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    tableHeaders: {
      type: Array,
      default: () => [],
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    itemTotal: {
      type: Number,
      default: 0,
    },
    actionViewRouteLink: {
      type: String,
      default: '',
    },
    breakpoint: {
      type: Number,
      default: 960,
    },
    defaultSortKey: {
      type: Array,
      default: () => ['create_date'],
    },
    defaultSortDesc: {
      type: Array,
      default: () => [true],
    },
    disableFirstLastPage: {
      type: Boolean,
      default: false,
    },
    disableFooter: {
      type: Boolean,
      default: false,
    },
    disableHeader: {
      type: Boolean,
      default: false,
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
    disableToolbar: {
      type: Boolean,
      default: false,
    },
    enableExpand: {
      type: Boolean,
      default: false,
    },
    enableFiltering: {
      type: Boolean,
      default: false,
    },
    enableGroupBy: {
      type: Boolean,
      default: false,
    },
    enableMultiTypeSearch: {
      type: Boolean,
      default: false,
    },
    enableSearch: {
      type: Boolean,
      default: false,
    },
    enableSelectToggle: {
      type: Boolean,
      default: false,
    },
    enableSort: {
      type: Boolean,
      default: false,
    },
    isDense: {
      type: Boolean,
      default: false,
    },
    isFixedHeader: {
      type: Boolean,
      default: false,
    },
    isMultiSort: {
      type: Boolean,
      default: false,
    },
    isMustSort: {
      type: Boolean,
      default: true,
    },
    isSingleExpand: {
      type: Boolean,
      default: false,
    },
    isSingleSelect: {
      type: Boolean,
      default: false,
    },
    page: {
      type: Number,
      default: 1,
    },
    pageLimit: {
      type: Number,
      default: 10,
    },
    pageLimitOptions: {
      type: Array,
      default: () => [10, 20, 50, 100],
    },
    searchPlaceholder: {
      type: String,
      default: '搜尋',
    },
    searchType: {
      type: String,
      default: ''
    },
    searchTypeOptions: {
      type: Array,
      default: () => [],
    },
    tableEmptyText: {
      type: String,
      default: '沒有資料'
    },
    enableRefresh: {
      type: Boolean,
      default: false,
    },
    enableExport: {
      type: Boolean,
      default: false,
    },
    enableClearAll: {
      type: Boolean,
      default: false,
    },
    showTopSlot: {
      type: Boolean,
      default: false,
    },
    disableShadow: {
      type: Boolean,
      default: false,
    },
    selectedTableRow: {
      type: Array,
      default: () => [],
    },
    isInputTable: {
      type: Boolean,
      default: false
    },
    headerInputFields: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    processing() {
      return this.isLoading
    },
    hideDefaultHeader() {
      return false;
    },

    uploading() {
      return this.$store.getters.isLoading
    },
  },

  // ------ page variables ------
  data: () => ({
    tableInit: true,
    searchText: '',
    options: {},
    selectedRow: [],
    icon: {
      mdiEyeOutline,
      mdiCheck,
      mdiClose,
      mdiRefresh,
      mdiMagnify,
      mdiCalendarSearch,
      mdiTableArrowRight,
      mdiTrashCanOutline,
    },
  }),

  watch: {
    options: {
      handler() {
        this.updateOptions()
      },
      deep: true,
    },
    selectedTableRow: {
      handler(val) {
        this.selectedRow = val;
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    updateOptions() {
      if (!this.tableInit) {
         if (this.options.itemsPerPage !== this.pageLimit) {
            this.$emit("update:page", 1);
         } else {
            this.$emit("update:page", this.options.page);
         }

         this.$emit("update:pageLimit", this.options.itemsPerPage);
         this.$emit("options-update", this.options);
      }
      this.tableInit = false;
    },
    clickActionView(id) {
      if (this.actionViewRouteLink) {
        this.$router.push({ name: this.actionViewRouteLink, params: { id: id } });
      } else {
        this.$emit('view-clicked', id);
      }
    },
    clickActionDelete(id) {
      this.$emit('delete-clicked', id);
    },
    clickClearAll() {
      this.searchText = '';
      this.$emit('all-search-cleared');
    },
    clickRefresh() {
      this.$emit('refresh-clicked')
    },
    clickExport() {
      this.$emit('export-clicked')
    },
    inputUpdate(id, key, value) {
      this.$emit('table-input-updated', {
        id, key, value
      });
    },
    previewImg(img) {
      if (this.$validate.DataValid(img)) {
        window.open(`${this.$mediaPath}${img}`);
      } else {
        this.$store.dispatch('toggleSnack', {
          show: true,
          message: '檔案不存在',
          type: 'error',
          refresh: false,
          redirect: '',
        })
      }
    },
    disabledInput(item, header) {
      if (header.itemInput && header.itemInput.disabled === true) {
        return true;
      }

      let disabled = false;
      const disableRelatedKeys = header.disableRelatedKeys;
      if (this.$validate.DataValid(item) && this.$validate.DataValid(disableRelatedKeys)) {
        for (const i in disableRelatedKeys) {
          if (this.$validate.DataValid(item[disableRelatedKeys[i]])) {
            disabled = true;
            break
          }
        }
      }
      return disabled;
    },
  },

  created() {
    const query = this.$route.query;
    if (this.$validate.DataValid(query['q'])) {
      this.searchText = query['q'];
    } else {
      this.searchText = "";
    }

    if (this.$validate.regexNumber(query['p']) && parseInt(query['p']) > 0) {
      this.$set(this.options, "page", parseInt(query['p']));
    }

    if (this.$validate.regexNumber(query['l']) && parseInt(query['l']) > 0) {
      this.$set(this.options, "itemsPerPage", parseInt(query['l']));
    }
  },
}
</script>

<style lang="scss" scoped>
.v-data-table.custom-table {
    & .v-data-table-header-mobile .v-text-field .v-select__selections {
        margin-top: 0.5rem;
    }

    & > .v-data-table__wrapper {
        & > table {
            & > tbody > tr > th,
            & > thead > tr > th,
            & > tfoot > tr > th {
                font-size: 0.825rem;
                font-weight: 500;
            }
        }

        & .v-data-table__mobile-table-row {
            padding-top: 1rem;
        }

        & .v-data-table__mobile-row {
            min-height: 2.2rem;
            margin: 10px 0;

            &:last-of-type {
                padding-bottom: 1rem;
            }
        }
    }

    & table > tbody > tr:hover {
        background: transparent !important;
    }
}

.input-datatable {
    & .v-input--is-disabled {
        background: #fafafa !important;
        opacity: .7;
    }
    & table {
        display: block;

        & > thead,
        & > tbody {
            width: 100%;
            display: block;

            & > .v-data-table__empty-wrapper {
                width: 100%;
                & > td {
                    width: 100% !important;
                    max-width: unset !important;
                    justify-content: center;
                }
            }

            & > tr {
                display: flex;
                width: 100%; 

                & > th,
                & > td {
                    display: flex;
                    align-items: center;

                    &.text-center {
                        justify-content: center;
                    }

                    &:last-of-type {
                        flex: 1;
                    }

                    &.input-cell {
                        width: 130px;
                        min-width: 130px;

                        & > .custom-input {
                            max-width: 100% !important;
                        }
                    }

                    &.text-cell {
                        width: 150px !important;
                        min-width: 150px !important;
                    }
                }
            }
        }
    }


    &.select-datatable {
        & table {
            & > thead,
            & > tbody {
                & > tr:not(.v-data-table__empty-wrapper) {
                    & > th,
                    & > td {
                        &:first-of-type {
                            width: 80px !important;
                            min-width: 80px !important;
                            max-width: 80px !important;
                        }
                    }
                }
            }
        }
      
    }
}

.custom-table-toolbar {
    & .toolbar-left-items {
        max-width: 60%;
        flex-wrap: wrap;
        flex: 1;

        & > .v-input.search-select-field {
            max-width: 250px;
        }

        & > .v-input:not(.search-select-field),
        & > .custom-input {
            max-width: 320px;
        }

        & > .custom-input {
            display: flex;
            flex-direction: column;
            height: auto;
            flex-grow: 1;
            flex-wrap: wrap;
            min-width: 0;
        }
    }

    & .toolbar-right-items {
        align-items: center;
        justify-content: flex-end;
        flex-wrap: wrap;
        flex: 1;

        & > .v-btn {
            margin: 10px 0;
        }
    }

    & .v-input--selection-controls .v-input__slot > .v-label {
        font-size: 0.875rem;
    }

    & .v-input input,
    & .v-input label {
        font-size: 0.825rem;
    }
}

@media screen and (max-width: 1600px) {
    .custom-table-toolbar .v-toolbar__content {
        flex-wrap: wrap;

        & .spacer {
            display: none;
        }
    }
}
</style>
